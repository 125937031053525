
import Vue from "vue";
import Component from "vue-class-component";
import BadgeButton from "./BadgeButton.vue";
@Component({
  props: {
    items: {
      type: Array,
      default: []
    },
    focus: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: "secondary"
    }
  },
  components: {
    BadgeButton
  }
})
export default class ButtonRow extends Vue {}
