
import Vue from "vue";
import Component from "vue-class-component";
import Overlay from "./Overlay.vue";
import { Statement } from "../../lib/Statement";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    Overlay
  }
})
export default class TextCategoryEditor extends Vue {

  text = "";
  @Prop() items: Statement[] | undefined;
  mounted(){
    if ( this.items) {
      this.text = this.items.map(item => item.text).join("\n");
      setTimeout(() => {
        (<HTMLElement>this.$refs.textarea).focus();
      }, 0);
    }
  }
}
