
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class ShortcutLixt extends Vue {
  shortcuts: Shortcut[] = [
    {
      key: "i",
      mod: false,
      func: "Сфокусироваться на поле ввода",
      block: "Везде"
    },
    {
      key: "1...5",
      mod: true,
      func: "Выбрать подсказку из предикатора",
      block: "Поле ввода"
    },
    {
      key: "Backspace",
      mod: true,
      func: "Очистить поле ввода",
      block: "Поле ввода"
    },
    {
      key: "↑",
      mod: true,
      func: "Переключиться на предыдущий чат",
      block: "Поле ввода"
    },
    {
      key: "↓",
      mod: true,
      func: "Переключиться на следующий чат",
      block: "Поле ввода"
    },
    {
      key: "0",
      mod: true,
      func: "Сфокусироваться на быстрых фразах",
      block: "Везде"
    },
    {
      key: "1...6",
      mod: false,
      func: "Выбрать быструю фразу",
      block: "Быстрые фразы"
    },
    {
      key: "Ж",
      mod: true,
      func: "Сфокусироваться на банке фраз",
      block: "Везде"
    },

    {
      key: "1...9",
      mod: false,
      func: "Выбрать категорию/фразу",
      block: "Банк фраз"
    },
    {
      key: "V",
      mod: false,
      func: "Включить режим вставки",
      block: "Банк фраз"
    },
    {
      key: "R",
      mod: false,
      func: "Выбрать случайную фразу",
      block: "Банк фраз"
    }
  ];
}

interface Shortcut {
  key: string;
  mod: boolean;
  block: "Везде" | "Поле ввода" | "Быстрые фразы" | "Банк фраз";
  func: string;
}
