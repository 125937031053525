
import fireapp from "@/lib/fireapp";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Brain extends Vue {
  query = "";
  response: string|null = null;
  loading = false;
  async send() {
    if(this.loading) return;
    this.loading = true;
    try {
      const { data } = await fireapp.functions().httpsCallable("chatbot")({
        phrase: this.query,
      });
      const { text } = data;
      this.response = text;
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
