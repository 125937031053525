
import Vue from "vue";
import Component from "vue-class-component";
import { Question } from "../../../functions/src/Question";

import Store from "@/lib/Store";
import fireapp from "@/lib/fireapp";
import { Prop, Watch } from "vue-property-decorator";
const NameProps = Vue.extend({
  props: {
    name: String,
  },
});
@Component
export default class PhraseMaker extends NameProps {
  store = new Store();
  questions: Question[] = [];
  loading = false;

  @Prop() name: string = "";
  @Watch("name") onName(value: string) {
    if (!this.$data.questions) return value;
    const question = (<Question[]>this.$data.questions).find(
      (item) => item.uid === "name"
    );
    if (question) {
      question.value = value;
    }
  }
  async save() {
    this.$emit("saved");
    // this.loading = true;
    try {
      await fireapp.functions().httpsCallable("createStatement")({
        questions: this.questions,
      });
    } catch (e) {
      console.error(e);
    }

  }

  created() {
    this.store.factory
      .child("questions")
      .once("value")
      .then((snap) => {
        const raw = snap.val();
        this.questions = [];
        for (const row of raw) {
          this.questions.push(
            new Question(
              row.uid,
              row.label,
              row.phrases,
              row.category,
              row.type
            )
          );
        }
      });
  }
}
