
import Vue from "vue";
import Component from "vue-class-component";
import Store from "@/lib/Store";
import { Category } from "../../lib/Category";
import fireapp from "../../lib/fireapp";

@Component
export default class ImportGlobal extends Vue {
  store = new Store();
  categories: Category[] = [];
  loading = true;

  get dones(): boolean[] {
    return this.categories.map(() => false);
  }

  async select(index: number, id: string, force = false) {
    this.loading = true;
    const res = await fireapp.functions().httpsCallable("importFromGlobal")({
      id,
      force
    });

    if (res.data === "exists") {
      const answer = await this.$dialog.confirm({
        title: "Перезаписать?",
        text: "Категория уже была загружена, хотите перезаписать?",
        actions:{
          false:"нет",
          true:"да"
        }
      });
      if (answer) {
        
        await this.select(index, id, true);
      }
    }

    this.loading = false;
    this.dones[index] = true;
  }

  created() {
    this.store.global
      .child("Category")
      .once("value")
      .then(snap => {
        const categories: Category[] = Store.sortItems(
          Object.values<Category>(snap.val())
        );

        this.categories = categories;
        this.loading = false;
      });
  }
}
