
import Vue from "vue";
import Component from "vue-class-component";
import TTS from "../lib/TTS";

import VoiceSettings from "./components/VoiceSettings.vue";
import PhraseMaker from "./components/PhraseMaker.vue";
import Store from "@/lib/Store";

@Component({
  components: {
    VoiceSettings,
    PhraseMaker,
  },
})
export default class Setup extends Vue {
  tts = TTS.instance;
  step: number = 1;
  name: string | null = null;
  gender: boolean | null = null;
  valid: boolean[] = [false, false, false];
  checkCode: number | null = null;
  trueCheckCode = 1000 + Math.floor(Math.random() * 8999);

  next() {
    if ((<any>this.$refs["form" + this.step]).validate()) {
      this.step++;
    }
  }
  async done() {
    const store = new Store();
    const root = store.root;
    if (root) {
      await root.child("inited").set(true);
      console.log('setted inited as true');
      
    }
    this.$emit("inited");
  }

  nameRules: Array<(v: string) => boolean | string> = [
    (v) => !!v || "Обязательно введите имя",
    (v) => (v && v.trim().split(" ").length < 2) || "Введите только имя",
  ];
  genderRules = [
    (v: any) => {
      return v !== null || "Обязательно укажите";
    },
  ];
  codeRules = [
    (v: number) =>
      v == this.trueCheckCode || "Неверный код, послушайте код и введите его",
  ];
}
