
import Vue from "vue";
import fireapp from "../lib/fireapp";
import Component from "vue-class-component";

@Component
export default class Auth extends Vue {
  email: string = "";
  password: string = "";
  error: string | null = null;
  isRegistrationMode = false
  valid = false;
  async login() {
    this.error = null;
    if (!(<any>this.$refs.form).validate()) return;
    try {
      const res = await fireapp
        .auth()
        .signInWithEmailAndPassword(
          this.email.trim().toLowerCase(),
          this.password.trim()
        );

      this.$emit("login", res.user);
    } catch (e) {
      this.error = (e as Error).message;
      this.password = "";
    }
  }
   async register() {
    this.error = null;
    if (!(<any>this.$refs.form).validate()) return;

    try {
      await fireapp
        .auth()
        .createUserWithEmailAndPassword(
          this.email.trim().toLowerCase(),
          this.password
        );
      this.login();
    } catch (error) {
      this.error = (error as Error).message;
    }
  }

   async resetPassword() {
    if (!(<any>this.$refs.email).validate()) return;

    if (
      !(await this.$dialog.confirm({
        title: "Сброс пароля",
        text: "Вы уверены, что хотите сбросить пароль для " + this.email,
        actions: ["Да", "Нет"]
      }))
    )
      return;
    try {
      await fireapp.auth().sendPasswordResetEmail(this.email);
    } catch (error) {
      return this.$dialog.error({
        title: "Ошибка",
        text: (error as Error).message
      });
    }
    return this.$dialog.warning({
      title: "Сброс пароля",
      text: "Вы уверены, что хотите сбросить пароль для " + this.email
    });
  }

  public passwordCheck(value: string) {
    return value.length < 8 ? "Пароль должен быть больше 7 символов." : true;
  }

  public emailCheck(value: string) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Неверный емейл.";
  }
}
