
import Vue from "vue";
import Component from "vue-class-component";

import { QWERTY } from "./../../constants";
import Overlay from "./Overlay.vue";
import BadgeButton from "./BadgeButton.vue";
import TextCategoryEditor from "./TextCategoryEditor.vue";
import Reader from "./Reader.vue";
import { Prop } from "vue-property-decorator";

@Component({
  components: { Overlay, BadgeButton, Reader, TextCategoryEditor },
  filters: {
    hintFilter(i: number) {
      if (i > 8 && QWERTY[i - 9]) {
        return QWERTY[i - 9].toUpperCase();
      }
      return i + 1;
    }
  }
})
export default class LList extends Vue {
  caller: Function | null = null;
  isEditor = false;
  isReader = false;
  @Prop() items: Array<any> | undefined;
  @Prop() dkey: String | undefined;
  @Prop() title: String | undefined;
  @Prop() isPaste: boolean | undefined;
  @Prop() type: "category" | "statement" | undefined;

  get tstatement(): boolean {
    return this.type === "statement";
  }

  select(item: any) {
    if (this.caller !== null) {
      this.caller(item);
      this.caller = null;
      return;
    }
    this.$emit("select", item);
  }
  choose(event: string) {
    if (!!this.caller) return;
    this.caller = function(item: any) {
      this.$emit(event, item);
    };
  }
}
