
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "Overlay",
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "active",
    event: "quit"
  }
})
export default class Overlay extends Vue {
  click(e: PointerEvent) {
    if (!e.target) return;
    if ((<Element>e.target).classList.contains("overlay")) {
      this.$emit("quit");
    }
  }
}
