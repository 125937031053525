
import Vue from "vue";
import Component from "vue-class-component";
import Overlay from "./Overlay.vue";
import { Prop, Watch } from "vue-property-decorator";
import TTS from "@/lib/TTS";

@Component({
  components: {
    Overlay
  }
})
export default class Reader extends Vue {
  page = 0;
  tts = TTS.instance;
  @Prop() items: { text: string }[] | undefined;

  windowInput(e: KeyboardEvent) {
    if (!<HTMLElement>this.$refs.reader) return this.distroy();
    switch (e.keyCode) {
      case 90:
        this.prev();
        break;
      case 77:
        this.next();
        break;
      case 80:
        this.say();
        break;
      case 83:
        this.stop();
        break;
      default:
        break;
    }
  }
  prev() {
    if (this.page > 0) this.page--;
  }
  next() {
    if (this.page < this.items!.length - 1) {
      this.page++;
    }
  }
  say() {
    this.tts.say(this.items![this.page].text);
  }
  stop() {
    this.tts.stop();
  }
  created() {
    window.addEventListener("keydown", this.windowInput);
  }
  distroy() {
    window.removeEventListener("keydown", this.windowInput);
  }
}
