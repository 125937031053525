
import fireapp from "./lib/fireapp";
import { Component, Vue, Emit } from "vue-property-decorator";
import Auth from "./blocks/Auth.vue";
import MainUI from "./blocks/MainUI.vue";
import Setup from "./blocks/Setup.vue";
import Store from "./lib/Store";
import ee from "./registerServiceWorker";

@Component({
  components: {
    Auth,
    MainUi: MainUI,
    Setup
  }
})
export default class App extends Vue {
  auth: boolean | null = null;
  inited: boolean | null = null;
  loading = true;
  login(user: firebase.User) {
    this.auth = true;
  }
  async mounted() {
    console.log('created');
    
    await fireapp.auth().setPersistence(fireapp.auth.Auth.Persistence.LOCAL);
    fireapp.auth().onAuthStateChanged(state => {
      this.auth = !!state;
      this.loading = false;
      
      if (this.auth) {

        const store = new Store();
        const root = store.root;
        if (root) {
          root.child("inited").on("value", snap => {
            this.inited = snap.val() === true;
            this.loading = false;     
            // this.inited=false
          });
          setTimeout(() => {
            this.inited=true
          }, 100);
        }
      }
    });

    ee.on("updated", async () => {
      if (
        await this.$dialog.confirm({
          text: "Перезагрузить сейчас?",
          title: "Есть обновление.",
          actions: ["Отменить", "Обновить"]
        })
      ) {
        window.location.reload();
      }
    });
  }
}
