
import Vue from "vue";
import Component from "vue-class-component";
import VoiceSettings from "./components/VoiceSettings.vue";
import AdaptiveSettings from "./components/AdaptiveSettings.vue";
import ImportGlobal from "./components/ImportGlobal.vue";

@Component({
  components: { VoiceSettings, AdaptiveSettings, ImportGlobal }
})
export default class Settings extends Vue {
  tab = 0;
}
