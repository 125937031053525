
import LocalMemory from "@/lib/LocalMemory";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class LHeader extends Vue {
  @Prop() settingsMode: boolean | undefined;
  @Prop({ default: 0 }) chat: number | undefined;
  saveOnSay = false;
  mounted() {
    this.saveOnSay = !!LocalMemory.instance.getBoolean("saveOnSay", false);
  }
  saveOnSayClick() {
    this.saveOnSay = !this.saveOnSay;
    LocalMemory.instance.setBoolean("saveOnSay", this.saveOnSay);
  }
}
