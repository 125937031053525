
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class BadgeButton extends Vue {
  @Prop() hint: Number | String | undefined;
  @Prop({ default: true }) focus: boolean|undefined;
  @Prop({ default: false }) overlap: boolean|undefined;
  @Prop({ default: false }) top: boolean|undefined;
  @Prop({ required: true }) value: String|undefined;
  @Prop({ default: "secondary" }) color: String|undefined;
}
